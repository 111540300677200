import { SsnStringPatternsByCountryCodes } from "types";
import { canadianStates, countriesFieldNames, stateFieldNames } from "../constants";

export const isPostalCodeValid = (postalCode: string): boolean => {
  const usRegExp = /^\d{5}(-\d{4})?$/; // 5 digits
  const canadaRegExp = /^[ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVXY][ -]?\d[ABCEGHJKLMNPRSTVXY]\d$/i; // 'A1A 1A1', 'A1A1A1' or 'A1A-1A1' format

  if (!postalCode) {
    return false;
  }
  return usRegExp.test(postalCode) || canadaRegExp.test(postalCode);
};

export const isPhoneValid = (phoneNumber: string): boolean => {
  if (!phoneNumber) {
    return false;
  }
  return phoneNumber.replace(/[\D]+/g, "")?.length === 10;
};

// also consider owner[0].* fieldName
export const checkIfIsCountryField = (fieldName: string): boolean =>
  countriesFieldNames.some(countryFieldName => fieldName?.includes(countryFieldName));

// also consider owner[0].* fieldName
export const checkIfIsStateField = (fieldName: string): boolean =>
  stateFieldNames.some(stateFieldName => fieldName?.includes(stateFieldName));

export const restrictFieldOptionsBySelectedCountry = (fieldOptions: any, block: any) => {
  const nearestCountryField = block.fields.find((f: any) => checkIfIsCountryField(f.ocaConfig?.config?.fieldName));
  if (!nearestCountryField) {
    return fieldOptions;
  }

  const nearestCountryFieldValue = nearestCountryField.value;
  switch (nearestCountryFieldValue) {
    case "US":
      return fieldOptions?.filter((fo: any) => !canadianStates.includes(fo.optionValue));
    case "CA":
      return fieldOptions?.filter((fo: any) => canadianStates.includes(fo.optionValue));
    default:
      return fieldOptions;
  }
};

export const getSsnTitleAndStringPatternByNearestCountryField = (field: any, block: any): Record<string, string> => {
  const result = {
    title: field.config.title,
    ssnStringPattern: SsnStringPatternsByCountryCodes.US,
  };

  const nearestCountryField = block?.fields.find((f: any) => checkIfIsCountryField(f.key));
  if (!nearestCountryField) {
    return result;
  }

  switch (nearestCountryField.value) {
    case "CA":
      result.title = "Social Insurance Number";
      result.ssnStringPattern = SsnStringPatternsByCountryCodes.CANADA;
      break;
    default:
      result.title = field.config.title;
      result.ssnStringPattern = SsnStringPatternsByCountryCodes.US;
  }

  return result;
};
