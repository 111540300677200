import { VendorOpportunity } from "modules/vendorOpportunity";
import { Invoice } from "../types";

export interface NetFinanceAmountParams {
  netRate: number;
  salesTax: number;
  totalFees: number;
  downPayment: number;
  equipmentCost: number;
}

export interface InvoiceTotalParams {
  netRate: number;
  salesTax: number;
  totalFees: number;
  equipmentCost: number;
}

export interface NetRateParams {
  tradeOwedBalanceAmount: number;
  tradeAllowanceGrossTradeIn: number;
}

export interface TotalFeesParams {
  freightLaborInstallation: number;
  manufacturerRebateAmount: number;
  invoiceWarrantyAndInsuranceCharges: number;
}

class InvoiceCalculations {
  public netFinanceAmount(params: NetFinanceAmountParams) {
    return Math.max(params.equipmentCost + params.totalFees + params.salesTax - params.netRate - params.downPayment, 0);
  }

  public invoiceTotal(params: InvoiceTotalParams) {
    return Math.max(params.equipmentCost + params.totalFees + params.salesTax - params.netRate, 0);
  }

  public netRate(params: NetRateParams) {
    return Math.max(params.tradeAllowanceGrossTradeIn - params.tradeOwedBalanceAmount, 0);
  }

  public totalFees(params: TotalFeesParams) {
    return Math.max(
      params.freightLaborInstallation + params.invoiceWarrantyAndInsuranceCharges - params.manufacturerRebateAmount,
      0
    );
  }

  public byFullVO(vo: VendorOpportunity) {
    const netRate = this.netRate({
      tradeOwedBalanceAmount: vo?.tradeOwedBalanceAmount ?? 0,
      tradeAllowanceGrossTradeIn: vo?.tradeAllowanceGrossTradeIn ?? 0,
    });

    const totalFees = this.totalFees({
      freightLaborInstallation: vo?.freightLaborInstallation ?? 0,
      manufacturerRebateAmount: vo?.manufacturerRebateAmount ?? 0,
      invoiceWarrantyAndInsuranceCharges: vo?.invoiceWarrantyAndInsuranceCharges ?? 0,
    });

    const invoiceTotalValue = this.invoiceTotal({
      netRate,
      totalFees,
      salesTax: vo?.salesTaxOnInvoice ?? 0,
      equipmentCost: parseFloat(vo?.equipmentCost ?? "0"),
    });

    const financeAmount = this.netFinanceAmount({
      netRate,
      totalFees,
      downPayment: vo?.downPayment ?? 0,
      salesTax: vo?.salesTaxOnInvoice ?? 0,
      equipmentCost: parseFloat(vo?.equipmentCost ?? "0"),
    });

    return { netRate, totalFees, invoiceTotalValue, financeAmount };
  }

  public byInvoice(invoice: Invoice) {
    const {
      downPayment,
      equipmentCost,
      salesTaxOnInvoice,
      tradeOwedBalanceAmount,
      manufacturerRebateAmount,
      freightLaborInstallation,
      tradeAllowanceGrossTradeIn,
      invoiceWarrantyAndInsuranceCharges,
    } = invoice;

    const netTrade = this.netRate({ tradeOwedBalanceAmount, tradeAllowanceGrossTradeIn });

    const totalFees = this.totalFees({
      freightLaborInstallation,
      manufacturerRebateAmount,
      invoiceWarrantyAndInsuranceCharges,
    });

    const invoiceTotal = this.invoiceTotal({
      netRate: netTrade,
      totalFees,
      equipmentCost,
      salesTax: salesTaxOnInvoice,
    });

    const netFinanceAmount = this.netFinanceAmount({
      netRate: netTrade,
      totalFees,
      downPayment,
      equipmentCost,
      salesTax: salesTaxOnInvoice,
    });

    return { invoiceTotal, netFinanceAmount, netTrade, totalFees };
  }
}

export const invoiceCalculations = new InvoiceCalculations();
